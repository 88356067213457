import * as React from "react";
import FirstApp from "../static/images/first_app.svg";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { faArrowRight, faArrowsAlt, faCoffee, faComment, faPaperPlane, faTachometerAlt, faTasks } from "@fortawesome/free-solid-svg-icons";

const Index: React.FC = () => {

    // const { pathname, hash, key } = useLocation();

    // useEffect(() => {
    //     // if not a hash link, scroll to top
    //     if (hash === '') {
    //         window.scrollTo(0, 0);
    //     }
    //     // else scroll to id
    //     else {
    //         setTimeout(() => {
    //             const id = hash.replace('#', '');
    //             const element = document.getElementById(id);
    //             if (element) {
    //                 element.scrollIntoView();
    //             }
    //         }, 0);
    //     }
    // }, [pathname, hash, key]);

    return (
        <>
            <SEO
                title="Home - Leading Apps"
                description="Wij ontwikkelen maatwerk websites in de buurt van Eindhoven. Geoptimaliseerd voor Google en met een design op maat. Plan vrijblijvend een intakegesprek in!"
                article={false}
            />

            <Header />

            <section id="heading">
                <div className="row g-0 blue-bg pb-5 pt-3">
                    <div className="col width-wrapper">
                        <ul>
                            <li>
                                <h1 className="medium-top bold white mt-5">
                                    De softwareontwikkelaar waarmee ondernemers
                                </h1>
                                <h1 className="medium-bottom bold green pb-3">
                                    weer grip krijgen op technologie
                                </h1>
                                <h5 className="white text-opacity-8 mt-3 mb-5 pb-5 mob-half-width">
                                    Bij ontwikkelen komt meer kijken dan alleen de software.
                                    Met Leading Apps zet je de eerste stap in de juiste richting, maar als je een vraag hebt, dan heet onze support niet voor niets zo.
                                    Wij kennen de technologie van vandaag en ondersteunen je waar nodig.
                                    Wij leveren op maat gemaakte websites en mobiele applicaties, waar jij de vruchten van kunt blijven plukken.
                                </h5>
                                <Link to="/contact" className="mh-centered">
                                    <button className="btn btn-primary purple-bg" type="button">
                                        <span>Ik wil grip op software </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                    </button>
                                </Link>
                            </li>
                            <li className="h-centered mobile-hide pb-5">

                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="section-spacer-top"></div>

            <div className="width-wrapper">

                <section id="ontwikkelaar">

                    <div className="row mob-col-swap g-0">
                        <div className="col ">
                            <ul>
                                <li>
                                    <div className="mockup-wrapper">
                                        <FirstApp className="mockup-container" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col fancy-text-col mh-centered">
                            <ul>
                                <li>
                                    <h1 className="top bold mh-centered">Een ontwikkelaar</h1>
                                    <h1 className="bottom bold purple">waar je iets aan hebt.</h1>
                                </li>
                                <li>
                                    <Link to="/contact" className="mh-centered">
                                        <button className="btn btn-primary mt-3" type="button">
                                            <span>Neem contact op </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="maatwerk">

                    <div className="section-spacer" ></div>

                    <div className="row g-0" >
                        <div className="col-md-6 fancy-text-col">
                            <ul>
                                <li>
                                    <h1 className="top bold purple">Laat onze technologie voor je werken</h1>
                                    <h5 className="grey larger m5">Ontdek de voordelen van onze applicaties voor werknemers, klanten en partners.</h5>
                                </li>
                                <li>
                                    <div>
                                        <div className="btn card-body light-grey my-4 dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faTachometerAlt} size="lg" className="me-3 icon-purple" /><h4 className="bold card-text black">Bekijk de voordelen van een applicatie op maat</h4>
                                        </div>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <h6 className="grey">Je applicatie is altijd beschikbaar en het belangrijkste: je loopt nooit (meer) achter! Met een handige applicatie of website zorg je er voor dat je kwaliteit uitstraald
                                                en de belangrijke informatie binnen handbereik is, dat herkennen uw partners meteen.</h6>
                                            <div className="centered">
                                                <Link to="/werkwijze">
                                                    <button className="btn btn-primary mt-3" type="button">
                                                        <span>De voordelen van maatwerk </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                                    </button>
                                                </Link>
                                            </div>
                                        </ul>
                                    </div>

                                </li>
                                <li>
                                    <div>
                                        <div className="btn card-body light-grey my-4 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faComment} size="lg" className="me-3 icon-purple" /><h4 className="bold card-text black">Communiceer je strategie</h4>
                                        </div>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <h6 className="grey">Een (web)applicatie laat zien waar je voor staat, wat je strategische voordelen zijn en hoe je zaken doet.
                                                Daarnaast kun je overal ter wereld je klanten op de hoogte stellen van de belangrijkste informatie.</h6>
                                            <div className="centered">
                                                <Link to="/werkwijze#stappenplan">
                                                    <button className="btn btn-primary mt-3" type="button">
                                                        <span>Duidelijke communicatie </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                                    </button>
                                                </Link>
                                            </div>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="btn card-body light-grey my-4 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faPaperPlane} size="lg" className="me-3 icon-purple" /><h4 className="bold card-text black">Stroomlijn je werkzaamheden</h4>
                                        </div>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <h6 className="grey">Wil je een duidelijk overzicht van je werkzaamheden of zakelijke informatie, efficient afgeleverd en op een presenteerdblaadje? Wil je makkelijker communiceren met klanten en partners?</h6>
                                            <div className="centered">
                                                <Link to="/werkwijze#doelgericht">
                                                    <button className="btn btn-primary mt-3" type="button">
                                                        <span>Dit wil ik! </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                                    </button>
                                                </Link>
                                            </div>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="btn card-body light-grey my-4 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faTasks} size="lg" className="me-3 icon-purple" /><h4 className="bold card-text black">Deel concepten en projecten</h4>
                                        </div>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <h6 className="grey">Overal ter wereld je data beheren, informatie up- en downloaden en gemakkelijk met anderen delen. Je gaat terug naar de kern van efficiënt werken
                                                waarbij data zorgvuldig en gestructureerd word gepresenteerd.</h6>
                                            <div className="centered">
                                                <Link to="/werkwijze">
                                                    <button className="btn btn-primary mt-3" type="button">
                                                        <span>Transparante omgeving </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                                    </button>
                                                </Link>
                                            </div>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="btn card-body light-grey my-4 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faArrowsAlt} size="lg" className="me-3 icon-purple" /><h4 className="bold card-text black">Koppel je producten</h4>
                                        </div>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <h6 className="grey">Niet meer op meerdere applicaties je zaken regelen en informatie beheren.
                                                Wij bouwen één platform die met alle services samenwerkt. Dat doen we door de nieuwste technologie toe te passen in het product waar jij naar op zoek bent.</h6>
                                            <div className="centered">
                                                <Link to="/werkwijze">
                                                    <button className="btn btn-primary mt-3" type="button">
                                                        <span>Koppelen met andere applicaties </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                                    </button>
                                                </Link>
                                            </div>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-1 col-sm-0">
                        </div>
                        <div className="col-md-5 col-sm-6 centered mobile-hide">
                            <ul >
                                <li >
                                    <div className="mockup-wrapper">
                                        <StaticImage
                                            src="../static/images/app_two_ipad_mini.png"
                                            alt=""
                                            className="rounded mx-auto d-block"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>


                <section id="simpel">

                    <div className="section-spacer" ></div>

                    <div className="row mob-col-swap g-0">
                        <div className="col">
                            <ul>
                                <li>
                                    <div className="mockup-wrapper">
                                        <StaticImage
                                            src="../static/images/iphone_agenda.png"
                                            alt=""
                                            className="rounded mx-auto d-block"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col fancy-text-col mh-centered">
                            <ul>
                                <li>
                                    <h1 className="top bold mh-centered">Simpele (web)applicaties</h1>
                                    <h1 className="bottom bold purple mh-centered">Daar blinken we in uit.</h1>
                                </li>
                                <li>
                                    <Link to="/contact" className="mh-centered">
                                        <button className="btn btn-primary mt-3" type="button">
                                            <span>Bekijk onze projecten </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="vriendelijk">
                    <div className="section-spacer"></div>

                    <div className="row mob-col-swap g-0">
                        <div className="col fancy-text-col">
                            <ul>
                                <li>
                                    <h1 className="bold purple">(Super)vriendelijke voorwaarden.</h1>
                                    <h5 className="grey">Een overzicht van onze voorwaarden en andere specificaties, eenvoudig te downloaden en gratis voor iedereen.
                                        Zo ben je overal van op de hoogte en heb je altijd de benodigde informatie bij de hand.</h5>
                                </li>
                                <li>
                                    <Link to="/voorwaarden" className="mh-centered">
                                        <button className="btn btn-primary mt-3" type="button">
                                            <span>Bekijk onze voorwaarden </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col mobile-hide">
                            <ul>
                                <li>
                                    <div className="mockup-wrapper">
                                        <StaticImage
                                            src="../static/images/iphone_documents_2.png"
                                            alt=""
                                            className="rounded mx-auto d-block"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="opstarten">
                    <div className="section-spacer"></div>

                    <div className="row g-0">
                        <div className="col">
                            <div className="card">
                                <div className="card-left">
                                    <ul>
                                        <li>
                                            <h1 className="cardtitle bold">Gratis opstartgesprek</h1>
                                            <h5 className="white text-opacity-6">Aarzel je toch nog om het jezelf makkelijker te maken en een betere grip op technologie te krijgen?
                                                We snappen veel, maar dát dan weer niet. We helpen je natuurlijk heel graag om net zo enthousiast te worden als wij! Want dat gunnen we jou.
                                                Bij Leading Apps heb je altijd een vliegende start, door het gratis opstartgesprek. Daarna gaan wij aan de slag om jouw ultieme product te realiseren.</h5>
                                        </li>
                                        <li>
                                            <Link to="/contact" className="mh-centered">
                                                <button className="btn btn-primary mt-3" type="button">
                                                    <span className="desktop-hide">Ik wil starten </span>
                                                    <span className="mobile-hide">Start nu met Leading Apps </span>
                                                    <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                                </button>
                                            </Link>
                                        </li>
                                    </ul>

                                </div>
                                <div className="">
                                    <StaticImage
                                        src="../static/images/office.jpg"
                                        alt=""
                                        className="rounded-card-right mx-auto d-block"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <section id="leading">

                <div className="section-spacer"></div>

                <div className="row g-0 purple-bg pb-5 pt-5">
                    <div className="col width-wrapper-mini">
                        <ul>
                            <li>
                                <h1 className="mega bold white mt-5">
                                    Start leading your business.
                                </h1>
                                <h5 className="white text-opacity-8 mt-3 mb-5">
                                    Bij ontwikkelen komt meer kijken dan alleen de software.
                                    Met Leading Apps zet je de eerste stap in de juiste richting, maar als je een vraag hebt, dan heet onze support niet voor niets zo.
                                    Wij kennen de technologie van vandaag en ondersteunen je waar nodig.
                                    Wij leveren de beste applicaties, zodat je daar de vruchten van plukt.
                                </h5>
                            </li>
                            <li className="h-centered">
                                <Link to="/contact">
                                    <button className="btn btn-primary mt-3 blue-bg" type="button">
                                        <span>Neem contact op </span><FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                    </button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Index;
